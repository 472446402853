.acc-settings-box {
  display: flex;
  width: 240px;
  height: 100%;
  padding: 40px 0px 0px 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--Spacing-space-10, 24px);
  flex-shrink: 0;
  border-right: 1px solid var(--stroke-colors, #ebebeb);
  background: #fff;
}
.containerPromoTitle {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 7px;
  margin-top: 36px;
}
.acc-settings-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  align-self: stretch;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: calc(100vh - 100px);
  overflow-y: auto;
  padding-bottom: 100px;

  // Scrollbar'ı gizle
  scrollbar-width: none; // Firefox için
  -ms-overflow-style: none; // IE ve Edge için

  &::-webkit-scrollbar {
    display: none; // Chrome, Safari ve Opera için
  }
}
.acc-settings-list-item {
  color: var(--Gray-Gray-700, #5e6278);
  display: flex;
  height: 52px;
  padding: 14px 17px 14px var(--Spacing-space-10, 24px);
  align-items: center;
  gap: 13px;
  align-self: stretch;
}

.acc-settings-list-item.active {
  color: var(--Gray-Gray-900, #181c32);
  background-color: #eef6ff;
  border-right: 2px solid var(--accent-color, #3e97ff);
}

.acc-settings-list-item:hover:not(.active) {
  background-color: #f7f9fc;
  color: var(--Gray-Gray-700, #5e6278);
}

.acc-users-box {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 40px;
  padding: 40px 20px 0px 20px;
  width: "100%";
  max-height: 100vh;
  overflow: hidden;
}
.acc-users-box-mobile {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 40px;
  padding: 12px 20px 0px 20px;
  width: 100%;
  max-height: 110vh;
  overflow: hidden;
}
.acc-users-box-content {
  flex-grow: 1;
  overflow-y: auto;
  width: 100%;
  padding-bottom: 50px;

  // Scrollbar'ı gizle
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
}
.acc-users-box-content::-webkit-scrollbar {
  display: none; /* Chrome, Safari, and Opera */
}

.acc-users-box-multiple-title {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 40px;
  padding: 40px 20px 0px 20px;
  width: "100%";
  max-height: 100vh;
  overflow-y: auto;
}
.acc-users-box-mobile-multiple-title {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 40px;
  padding: 0px 20px 0px 20px;
  width: 100%;
  overflow: hidden;
  max-height: 100vh;
  overflow-y: auto;
}
.acc-users-box-header {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: flex-start;
  width: 100%;
  max-width: "812px";
  top: 0;
  z-index: 100;
}

.acc-users-box-header-left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  max-width: "812px";
  width: auto;
}

.acc-users-box-header-title {
  color: var(--Gray-Gray-900, #181c32);
}

.acc-users-box-header-subtitle {
  color: var(--Gray-Gray-600, #7e8299);
}

.acc-users-box-header-button {
  display: flex;
  padding: 9px 12px;
  align-items: center;
  gap: 5px;
  border-radius: 50px;
  border: 1px solid var(--white-dark-white, #fff);
  background: var(--Blue-light, #0065e0);
  cursor: pointer;
  width: auto;
  min-width: 120px;
}

.acc-users-box-header-button-icon {
  display: flex;
  width: 20px;
  height: 20px;
  padding: 4.167px;
  justify-content: center;
  align-items: center;
}

.acc-users-box-header-button-icon img {
  width: 11.667px;
  height: 11.667px;
  flex-shrink: 0;
}

.acc-users-box-header-button-text {
  color: var(--white-dark-white, #fff);
  font-family: Inter !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 24px !important; /* 171.429% */
}

.edit-button {
  display: flex;
  padding: 9px 12px;
  align-items: center;
  justify-content: center;
  margin-top: 35%;
  gap: 10px;
  border-radius: 50px !important;
  border: 1px solid var(--Gray-Gray-800, #3f4254);
  background-color: transparent;
  cursor: pointer;
}

.account-users-box {
  display: flex;
  height: 100px;
  padding: 16px 0px;
  align-items: center;
  gap: 16px;
  align-self: stretch;
}

.account-users-avatar-name {
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: center;
}

.account-users-name {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  align-self: stretch;
  overflow: hidden;
  color: var(--Gray-Gray-800, #3f4254);
  text-overflow: ellipsis;
  align-items: center;
  justify-content: center;
  margin-top: 10%;
}

.account-users-image {
  display: flex;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
}

.account-users-image img {
  width: 40px;
  height: 40px;
  flex-shrink: 0;
  border-radius: 100px;
}

.add-user-acc-modal-body {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 24px;
  align-self: stretch;
}

.add-user-acc-modal-name-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  flex: 1 0 0;
  align-self: stretch;
}

.add-user-acc-modal-name-label {
  align-self: stretch;
  color: var(--Gray-Gray-900, #181c32);
}

.add-user-acc-modal-name-input {
  display: flex;
  height: 48px;
  padding: 17px 15px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 6px;
  border: 1px solid var(--Gray-Gray-500, #a1a5b7);
  background: var(--white-dark-white, #fff);
  color: var(--Gray-Gray-700, #5e6278);
}

.add-user-acc-modal-name-select {
  display: flex;
  height: 48px;
  padding: 17px 15px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 6px;
  border: 1px solid var(--Gray-Gray-500, #a1a5b7);
  color: var(--Gray-Gray-800, #3f4254);
  font-family: Inter !important;
  font-size: 14px !important;
  font-weight: 500 !important;
}
/* CSS */
.toggle-button {
  display: flex;
  width: 38px;
  height: 26px;
  padding: 4px;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  background-color: gray; /* Active background color */
  border-radius: 13px;
  cursor: pointer;
}
.toggle-button.active {
  background-color: #3e97ff;
}
.round-part {
  width: 20px;
  height: 20px;
  background-color: #ffffff; /* Default background color */
  border-radius: 50%;
  transform: translateX(-11px);
  flex-shrink: 0;
  transition: transform 0.3s, background-color 0.3s;
}

.round-part.active {
  transform: translateX(1px); /* Adjust this value to match your needs */
  background-color: #fff; /* Active background color */
}

.promoCodeIllustration {
  width: 195.548px;
  height: 191px;
}
.add-promo-modal-name-label {
  color: var(--Gray-Gray-900, #181c32);

  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.promo-box-title p:first-child {
  color: var(--white-dark-white, #fff);
}

.promo-box-subtitle {
  color: #e1e3ea !important;
}
.promo-box-title {
  padding: 30px;
}
//PMS
.pms-box {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 64px;
}
.pms-body-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
}
.pms-body-box-top {
  display: flex;
  padding: 24px 0px;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  border-bottom: 1px solid var(--stroke-colors, #ebebeb);
}

.pms-body-box-top-logo {
  display: flex;
  width: 44px;
  height: 44px;
  padding: 3.637px;
  justify-content: center;
  align-items: center;
  gap: 3.637px;
  flex-shrink: 0;
  border-radius: 10.911px;
  background: linear-gradient(315deg, #2c8bfb 100%, #1e5dff 100%);
}
.pms-body-box-top-logo img {
  width: 21.822px;
  height: 23.186px;
  flex-shrink: 0;
}
.pms-body-box-top-texts {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
  flex: 1 0 0;
  align-self: stretch;
}
.pms-body-box-top-subtitle {
  color: var(--Gray-Gray-600, #7e8299);
}
.pms-body-box-top-title {
  color: var(--Gray-Gray-900, #181c32);
}
.pms-body-box-top-btn {
  display: flex;
  padding: 12px 16px;
  align-items: center;
  gap: 10px;
  border-radius: 50px;
  border: 1px solid var(--Gray-Gray-800, #3f4254);
  color: var(--Gray-Gray-800, #3f4254);
  cursor: pointer;
  background: transparent;
}
.pms-body-box-bottom {
  display: flex;
  height: 78px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}
.pms-body-box-bottom-title {
  color: var(--Gray-Gray-900, #181c32);
  align-self: stretch;
}
.pms-bottom-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  margin-top: 32px;
}
.pms-body-box-bottom-logo {
  display: flex;
  width: 44px;
  height: 44px;
  padding: 3.637px;
  justify-content: center;
  align-items: center;
  gap: 3.637px;
  flex-shrink: 0;
  border-radius: 10.911px;
  border: 1px solid var(--Gray-Gray-300, #e1e3ea);
  box-shadow: 0px 8px 14px 0px rgba(15, 42, 81, 0.04);
}
.pms-body-box-bottom-logo img {
  width: 30px;
  height: 30px;
  flex-shrink: 0;
}

.booking-site-page {
  margin-bottom: 64px;
}

.promo-codes-page {
  @media (max-width: 450px) {
    max-width: 340px;
  }
}

//Booking site
.booking-site-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  width: 100%;
}
.booking-settings-box {
  display: flex;
  padding: 32px 0px;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  border-bottom: 1px solid var(--stroke-colors, #ebebeb);
}
.booking-settings-box-left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
  flex: 1 0 0;
}
.booking-settings-box-title {
  align-self: stretch;
  color: var(--Gray-Gray-900, #181c32);
}
.booking-settings-box-subtitle {
  color: var(--Gray-Gray-600, #7e8299);
  align-self: stretch;
}
.booking-settings-box-with-bottom-item {
  display: flex;
  padding: 32px 0px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  border-bottom: 1px solid var(--stroke-colors, #ebebeb);
  width: 100%;
}
.booking-settings-box-with-bottom-item-top {
  display: flex;
  align-items: center;
  gap: 12px;
  flex: 1 0 0;
  width: 100%;
}
.booking-site-input {
  display: flex;
  width: 820px;
  height: 48px !important;
  padding-left: 15px;
  justify-content: space-between;
  align-items: center;
  border-radius: 6px;
  border: 1px solid var(--Gray-Gray-500, #a1a5b7);
}
.link-url {
  color: #3e97ff;
}
.copy-icon {
  display: flex;
  width: 24px;
  height: 24px;
  padding: 1.25px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}

.booking-site-color-box {
  display: flex;
  align-items: center;
  gap: 10px;
}
.booking-site-color-box-left {
  display: flex;
  width: 30px;
  height: 30px;
  padding: 6px 7px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 7px;
  background: var(--blue900, #0052bd);
}
.booking-site-color-box-text {
  color: var(--Gray-Gray-800, #3f4254);
}
.booking-site-header-content-box {
  width: 284.777px;
  height: 190px;
  background: lightgray 50% / cover no-repeat;
  border-radius: 10px;
}
.booking-site-header-content-box img {
  width: 284.777px;
  height: 190px;
  border-radius: 10px;
}
.booking-settings-box-icon {
  display: flex;
  width: 42.735px;
  height: 42px;
  padding: 3.637px;
  justify-content: center;
  align-items: center;
  gap: 3.637px;
  flex-shrink: 0;
  border-radius: 10.911px;
  border: 1px solid var(--Gray-Gray-300, #e1e3ea);
  background: #fff;
  box-shadow: 0px 8px 14px 0px rgba(15, 42, 81, 0.04);
}
.payment-methods-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  margin-top: 44px;
}
.payment-methods-box-title {
  color: var(--Gray-Gray-900, #181c32);
}
.sticky-header {
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 100;
}
#message-creator-id .ql-container.ql-snow {
  border: none;
}
.set-message-button {
  display: flex;
  width: 38px;
  height: 38px;
  padding: 9px;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border-radius: 60px;
  border: 1px solid var(--Gray-Gray-500, #a1a5b7);
  background: transparent;
  cursor: pointer;
}

.container-settings {
  width: var(--mantine-breakpoint-lg);
  margin: auto;
}
