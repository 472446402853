$transition-default: all 0.2s ease;
$booking-height: 20%;
$booking-height-hover: 25%;
$calendar-day-height: 54px;
$header-day-height: 32px;

.popover-target {
  position: fixed;
  z-index: 9999;
  background: var(--mantine-color-gray-1);
  border-radius: var(--mantine-radius-md);
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  width: 300px;
  transition: $transition-default;
  opacity: 0;
  pointer-events: none;
  font-family: var(--mantine-font-family);

  &.visible {
    opacity: 1;
    pointer-events: auto;
  }

  .popover-content-area{
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .popover-content{
    display: flex;
    flex-direction: column;
    gap: 10px !important;
    background: var(--mantine-color-white);
    border-radius: var(--mantine-radius-md);
    padding:10px !important;
  }

  .guest-paid-box {
    background: var(--mantine-color-white);
    border-radius: var(--mantine-radius-md);
    padding: 10px;
    
    .details {
      background: var(--mantine-color-white);
      border-radius: var(--mantine-radius-md);
    }
  }

  .popover-booking-item{
    display: flex;
    flex-direction: column;
    gap: 0px;
    padding-bottom:10px;

    border-bottom: 1px solid var(--mantine-color-gray-2);
    &:last-child{
      border-bottom: none !important;
      padding-bottom:0px !important;
    }
  }
}

.botel-calendar {
  position: relative;
  overflow: visible;
  display: flex;
  flex-direction: column;
  gap: 4px;

  .calendar-main {
    display: grid;
    grid-template-columns: repeat(7, 1fr);

    .day {
      margin: 0.5px;
      padding: 2px;
      height: $calendar-day-height;
      position: relative;
      user-select: none;
      color: var(--mantine-color-gray-5);
      opacity: 0.4;

      // Day number styles
      .day-number {
        position: absolute;
        top: 4px;
        left: 4px;
        z-index: 3;
        padding: 1px 6px;
        border-radius: var(--mantine-radius-xs);
        font: {
          size: var(--mantine-font-size-xs);
          family: var(--mantine-font-family);
          weight: var(--mantine-font-weight-bold);
        }
        color: var(--mantine-color-dark-9);
      }

      .source-icon,
      .source-text {
        position: absolute;
        top: 4px;
        right: 4px;
        z-index: 3;
        opacity: 0;
        transition: opacity $transition-default;
        line-clamp: 1;
        max-width: 24px;
        display: flex;
        justify-content: start;
        align-items: center;
        overflow: hidden;
      }

      &.active-day {
        .source-icon,
        .source-text {
          opacity: 1;
        }

        &:hover {
          .source-icon,
          .source-text {
            opacity: 1;
          }
        }
      }

      &.active-day.first {
        .source-icon,
        .source-text {
          opacity: 1;
        }

        &:hover {
          .source-icon,
          .source-text {
            opacity: 1;
          }
        }
      }

      .source-icon {
        width: 16px;
        height: 16px;
        opacity: 0.6;
      }

      .source-text {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--mantine-color-gray-2);
        border-radius: var(--mantine-radius-xs);
        font-size: var(--mantine-font-size-xs);
        color: var(--mantine-color-gray-7);
        opacity: 0.6;
      }

      &.active-day {
        .source-icon,
        .source-text {
          opacity: 1;
        }
      }

      &:hover {
        .source-icon,
        .source-text {
          opacity: 1;
        }
      }

      // Hour grid styles
      .hour-grid {
        position: absolute;
        inset: 0;
        display: flex;
        align-items: center;
        background-image: repeating-linear-gradient(
          to right,
          rgba(0, 0, 0, 0.03) 0px,
          rgba(0, 0, 0, 0.03) 1px,
          transparent 1px,
          transparent calc(100% / 24)
        );

        .booking-indicator {
          position: absolute;
          top: 70%;
          transform: translateY(-50%);
          height: $booking-height;
          transition: $transition-default;
          background-color: var(--mantine-color-blue-1);
          opacity: 0.7;

          &.active {
            background-color: var(--mantine-color-blue-6) !important;
            opacity: 1;
            z-index: 2;
          }

          &:hover {
            height: $booking-height-hover;
            opacity: 1;
            z-index: 3;
          }
        }
      }

      // States
      &.active {
        color: var(--mantine-color-dark-9);
        font-weight: var(--mantine-font-weight-medium);
        opacity: 1 !important;
      }

      &.first .booking-indicator{
        border-top-left-radius: var(--mantine-radius-md);
        border-bottom-left-radius: var(--mantine-radius-md);
      }

      &.last .booking-indicator{
        border-top-right-radius: var(--mantine-radius-md);
        border-bottom-right-radius: var(--mantine-radius-md);
      }

      &.day-between {
        &.active-day {
          background-color: var(--mantine-color-blue-0);
          
          .booking-indicator {
            background-color: var(--mantine-color-blue-6);
            opacity: 1;
          }

          &.first .booking-indicator{
            border-top-left-radius: var(--mantine-radius-md);
            border-bottom-left-radius: var(--mantine-radius-md);
          }

          &.last .booking-indicator{
            border-top-right-radius: var(--mantine-radius-md);
            border-bottom-right-radius: var(--mantine-radius-md);
          }
        }
      }

      &.day-today {
        background-color: var(--mantine-color-gray-1);
        
        .day-number {
          background: var(--mantine-color-blue-4);
          color: white;
          font-weight: var(--mantine-font-weight-bold);
        }
      }
    }

    // Header days
    > .day:nth-child(-n+7) {
      height: $header-day-height;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      font: {
        family: var(--mantine-font-family-serif);
        size: var(--mantine-font-size-sm);
        weight: var(--mantine-font-weight-semibold);
      }
      color: var(--mantine-color-dark-3);
      opacity: 1 !important;
    }
  }

  .header {
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    span {
      font: {
        family: var(--mantine-font-family);
        size: var(--mantine-font-size-md);
        weight: var(--mantine-font-weight-semibold);
      }
    }

    &-buttons {
      display: flex;
      gap: 10px;
    }

    &-button {
      cursor: pointer;
      outline: none;
      background: none;
      border: none;
      border-radius: var(--mantine-radius-md);
      display: flex;

      &:hover {
        background: var(--mantine-color-gray-2);
      }
    }

    .month-year-box{
      display: flex;
      gap: 10px;
      align-items: center;
    }
  }
}
