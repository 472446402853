.reservation-detail-main {
  overflow-y: auto;
  overflow-x: hidden;
  min-width: 350px;
}

.flex-box {
  display: flex;
  text-wrap: wrap;

  @for $i from 1 through 5 {
    &.gap-#{$i * 5} {
      gap: #{$i * 5}px;
    }
  }

  @for $i from 1 through 5 {
    &.p-#{$i * 5} {
      padding: #{$i * 5}px;
    }
  }

  &.jsb {
    justify-content: space-between;
  }

  &.flex-column{
    flex-direction: column;
  }

  &.a-center {
    align-items: center;
  }

  &.column {
    flex-direction: column;
  }
}

.detail-box {
  display: flex;
  background: var(--mantine-color-gray-0);
  border-radius: var(--mantine-radius-md);
  padding: 10px;

  &.phone {
    justify-content: space-between;

    .phone-number {
      display: flex;
      flex-direction: column;
      align-items: center;

    }
  }
}

.guest-paid-box {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-top: 10px;
  justify-content: center;

  .details {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}
