.channel-box {
  border-radius: var(--mantine-radius-lg);
  padding: 30px 18px;
  position: relative;
  border: 1px solid var(--mantine-color-gray-3);
  transition: .3s;

  &:hover {
    cursor: pointer;
    background: var(--mantine-color-gray-1);
  }

  &.active {

    background: var(--mantine-color-gray-2);
  }

  .flex-box {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    &.column {
      flex-direction: column;
    }
  }

  .coming-soon {
    position: absolute;
    top: 0;
    right: 0;
    background: var(--mantine-color-red-7);
    padding: 4px 8px;
    line-height: 1px;
    border-radius: 0px 16px;
  }

}
