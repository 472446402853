.inbox-main-box {
  position: fixed;
  display: flex;
  width: 100%;
  margin: auto;
  overflow: hidden;
  background-color: #fff;
  height: calc(100vh - 77px);
  top: 70px;

  @media (max-width: 768px) {
    height: 100%;
    top: 0;
    padding-bottom: 0;
  }
}

.chat-card-reservation-details {
  display: flex;
  width: 100%;
  align-items: center;
  align-content: center;
  gap: 12px;
  flex-wrap: wrap;
}

.chat-card-reservation-details-box {
  display: flex;
  align-items: center;
  gap: 12px;
  flex: 1 0 0;
}

.chat-card-reservation-details-box-text-area {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
  flex: 1 0 0;
}

.chat-card-reservation-details-box-text-area-title {
  color: var(--Gray-Gray-900, #181c32);
  align-self: stretch;
}

.chat-card-reservation-details-box-text-area-title-des {
  align-self: stretch;
  color: var(--Gray-Gray-600, #7e8299);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 142.857% */
}

.chat-card-reservation-details-button {
  display: flex;
  padding: 12px 16px;
  align-items: center;
  gap: 10px;
  border-radius: 50px;
  border: 1px solid var(--Blue-light, #0065e0);
  background: var(--Blue-light, #0065e0);
  color: var(--white-dark-white, #fff);
  cursor: pointer;
}

.chat-card-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  align-self: stretch;
}

.chat-card-details-date {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  align-self: stretch;
  color: var(--Gray-Gray-700, #5e6278);
}

.chat-card-details-box {
  display: flex;
  padding: 12px 14px;
  align-items: center;
  gap: 60px;
  align-self: stretch;
  border-radius: 7px;
  background: var(--Gray-Gray-200, #f1f1f2);
}

.chat-card-details-box-details-area {
  display: flex;
  align-items: center;
  gap: 16px;
  flex: 1 0 0;
}

.chat-card-details-box-details-area-text {
  color: var(--Gray-Gray-600, #7e8299);
}

.chat-card-details-booking-logo {
  display: flex;
  width: 24px;
  height: 24px;
  padding-right: 0px;
  justify-content: center;
  align-items: center;
}

.chat-card-details-booking-logo img {
  width: 24px;
  height: 24px;
  flex-shrink: 0;
}

.message-inside {
  display: flex;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
}

.message-inside-avatar {
  display: flex;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
}

.message-inside-avatar img {
  width: 40px;
  height: 40px;
  flex-shrink: 0;
  border-radius: 100px;
  object-fit: cover;
}


.messages-income {
  display: flex;
  width: 100%;
  padding: 24px 12px;
  align-items: center;
  gap: 12px;
  border-bottom: 1px solid var(--stroke-colors, #ebebeb);
}

.messages-income-avatar {
  width: 50px;
  height: 50px;
  flex-shrink: 0;
  border-radius: 100px;
}

.messages-income-avatar img {
  width: 50px;
  height: 50px;
  flex-shrink: 0;
  border-radius: 100px;
  object-fit: cover;
}

.messages-income-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  flex: 1 0 0;
}

.messages-income-content-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.messages-income-content-confirm {
  color: var(--Success-Success-Active, #47be7d);
}

.messages-income-content-date {
  color: var(--Gray-Gray-600, #7e8299);
  text-align: right;
  width: 48.698px;
}

.messages-income-content-body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
}

.messages-income-content-body-name {
  color: var(--Gray-Gray-900, #181c32);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 15ch;
  width: 100%;
  display: inline-block;
}

.messages-income-content-body-preview {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  width: 100%;
}

.messages-income-content-body-preview-message {
  color: var(--Gray-Gray-900, #181c32);
  display: inline-block;
}

.messages-income-content-body-preview-counter {
  display: flex;
  width: 18px;
  padding: 4px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10.209px;
  border-radius: 1020.851px;
  background: var(--Gray-Gray-900, #181c32);
}

/* Check-in: Right half should be colored */
.half-selected-right {
  background: linear-gradient(to right, white 50%, #0065e0 50%);
  color: black !important;
}

/* Check-out: Left half should be colored */
.half-selected-left {
  background: linear-gradient(to left, white 50%, #0065e0 50%);
  color: black !important;
}

.filter-modal-title {
  color: var(--Gray-Gray-900, #181c32);

  /* Text md/Semibold */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  /* 150% */
}

.filter-modal-reset {
  color: var(--Blue-light, #0065e0);
  text-align: center;
  font-family: Inter;
  cursor: pointer;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  /* 142.857% */
}

.dot-bottom-left::before {
  content: "";
  position: absolute;
  width: 5px;
  height: 5px;
  background-color: red;
  /* Change color as needed */
  border-radius: 50%;
  bottom: 2px;
  left: 2px;
}

.MuiPickersDay-root {
  position: relative;
}

.dot-bottom-left::before {
  content: "";
  position: absolute;
  width: 5px;
  height: 5px;
  background-color: #1d1b20;
  /* Change color as needed */
  border-radius: 50%;
  bottom: 2px;
  left: 2px;
}

.MuiPickersDay-root {
  position: relative;
}

.tooltip {
  display: none;
  width: 204px;
  min-height: var(--Icon-icon-lg, 24px);
  padding: 8px 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  // right: 25px !important;
  border-radius: 10px;
  background: #020e1e;
  color: white;
  z-index: 1000;
}

.tooltip p {
  color: var(--Schemes-Inverse-On-Surface, #f5eff7);

  /* Text xs/Regular */
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  /* 150% */
}

.filter-modal-label {
  color: var(--Gray-Gray-900, #181c32);

  /* Text sm/Medium */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  /* 142.857% */
}


.mobile-ai-approve-button {
  display: flex;
  height: 38px;
  padding: var(--Spacing-space-6, 10px) var(--Spacing-space-9, 20px);
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  color: var(--Schemes-On-Secondary, #fff);
  text-align: center;
  border: none;
  /* Text sm/Medium */
  font-family: Inter;
  font-size: 14px;
  width: 101px;
  outline: none;
  padding: 0;
  border-radius: 20px;
  background: linear-gradient(0deg, #0065e0 0%, #0065e0 100%),
    var(--Schemes-On-Secondary, #fff);
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  /* 142.857% */
}

.ai-modal-title {
  color: var(--Gray-900, #101828);

  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
}

.ai-date {
  color: var(--Gray-Gray-600, #7e8299);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  /* 166.667% */
}

.ai-description {
  color: var(--Gray-700, #344054);

  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.ai-edit-button {
  width: inherit !important;
}

.custom-disabled {
  background-color: #938f99 !important;
  color: white !important;
}

.today-highlight {
  background: #181c32 !important;
  color: white !important;
}

.custom-disabled:hover {
  background-color: #89878c !important;
}

.messages-income-content-body-preview-counter-number {
  color: #fff;
  text-align: center;
}

.messages-income-content-footer {
  display: flex;
  align-items: center;
  gap: 59.936px;
  align-self: stretch;
  width: 100%;
}

.messages-income-content-footer-text {
  color: var(--Gray-Gray-500, #a1a5b7);
  text-overflow: ellipsis;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  /* 83.333% */
  letter-spacing: -0.36px;
}

.custom-quill {

  strong,
  b {
    font-weight: 700;
  }

  em {
    font-style: italic;
  }
}

.textEditorIndicator {
  margin: 0 5px;
  color: var(--Gray-500, #667085);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  cursor: pointer;
  line-height: 20px;
  /* 125% */
  text-decoration-line: underline;
}

.textEditorIndicator:hover {
  cursor: pointer;
  color: #0065e0;
}

.custom-quill .ql-toolbar.ql-snow {
  border-radius: 12px 12px 0px 0px;
  border: none;
  background: var(--Gray-200, #eaecf0);
  width: 100%;
  box-sizing: border-box;
  /* Toolbar genişliği konteyner genişliği kadar */
  // overflow: auto !important;
  white-space: nowrap !important;
  /* Toolbar'daki butonların yan yana sıralanmasını sağlar */
  // Scrollbar'ı gizle
  scrollbar-width: none; // Firefox için
  -ms-overflow-style: none; // IE ve Edge için

  &::-webkit-scrollbar {
    display: none; // Chrome, Safari ve Opera için
  }

  @media (max-width: 600px) {
    background: transparent;
    border: none;
    margin-left: 35px;
  }

  @media (max-width: 350px) {
    width: 70% !important;
    overflow: scroll;
  }
}

.ql-editor {
  background-color: white;
  border-radius: 10px;
}

.custom-quill .ql-container.ql-snow {
  border: 1px solid #ebebeb;
  width: 100%;
  box-sizing: border-box;
  /* Container genişliği konteyner genişliği kadar */

  @media (max-width: 600px) {
    min-width: 540px;
    border-top: 1px solid #ebebeb;
    padding-bottom: 8px;
  }
}

.custom-quill {
  @media (max-width: 600px) {
    display: flex;
    flex-direction: column-reverse;
  }
}

.custom-quill .ql-editor {
  min-height: 80px;
  max-height: 80px;
  overflow-y: auto;
  width: 100%;
  /* Editör genişliği konteyner genişliği kadar */
  box-sizing: border-box;
  /* Border ve padding'leri genişliğe dahil et */
  white-space: normal;
  /* Textin alt satıra geçmesini sağlar */

  @media (max-width: 600px) {
    min-height: 50px;
    max-height: 50px;
  }
}

/* Takvim ana stilleri */
.MuiPickersDay-root {
  margin: 0 !important;
  padding: 0 !important;
  height: 40px !important;
  width: 40px !important;
  border: none !important;
  font-size: 14px !important;

  &.Mui-selected {
    background-color: rgba(0, 101, 224, 0.2) !important;
    color: #000 !important;
    border-radius: 0 !important;
    font-weight: 400 !important;
  }
}

.MuiPickersDay-root.half-selected-right {
  border-radius: 50% !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  background: linear-gradient(to right, rgba(0, 101, 224, 0.2) 50%, #0065e0 50%) !important;
  color: #000 !important;
}

.MuiPickersDay-root.half-selected-left {
  border-radius: 50% !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  background: linear-gradient(to left, rgba(0, 101, 224, 0.2) 50%, #0065e0 50%) !important;
  color: #000 !important;
}

.MuiDateCalendar-root {
  .MuiDayCalendar-monthContainer {
    .MuiDayCalendar-weekContainer {
      margin: 0 !important;
      padding: 0 !important;
      gap: 0 !important;
    }
  }
}

.today-highlight {
  background: #181c32 !important;
  color: white !important;
}

.MuiPickersDay-root:not(.Mui-selected):not(.half-selected-right):not(.half-selected-left):hover {
  background-color: transparent !important;
}
