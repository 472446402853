.chat-card {
  width: 100%;
  min-width: 50%;
  max-width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  border-right: 1px solid #ebebeb;
  background: #fff;
  background-color: #fff;
  display: flex;
  align-self: stretch;

  @media (max-width: 768px) {
    padding: 0px 20px;
    position: absolute;
    top: 0;
    left: 0;
    min-width: 100%;
    max-width: 100%;
    z-index: 99;
    border: none;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s ease-in-out;

    &.mobile {
      opacity: 1;
      pointer-events: auto;
    }
  }

}

.chat-card-mobile {
  width: 100%;
  height: 100%;
  overflow: hidden;
}



.chat-header-box {
  padding: 16px 22px;
  display: flex;
  justify-content: space-between;
  position: relative;
  align-items: center;
  align-self: stretch;
  background: #fafafa;
  border-bottom: 1px solid var(--stroke-colors, #ebebeb);
  width: 100%;
  transition: 1s;

  &.playground {
    background: var(--mantine-color-gray-1);

  }

  @media (max-width: 768px) {
    padding: 0;
    align-items: flex-start;
    flex-direction: column;
  }

  .header-left {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;

    @media (max-width: 768px) {
      justify-content: space-between;
      height: 64px;
      border-bottom: 1px solid var(--stroke-colors, #ebebeb);
    }

    .chat-customer-image-box {
      display: flex;
      width: 40px;
      height: 40px;
      padding: 20px 18px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 100px;
      background: #FF8181;
      color: #FFF;
      font-size: 16px;
      font-weight: bold;
    }

    .chat-card-customer-name {
      display: flex;
      align-items: center;
      gap: 112px;
      color: var(--Gray-Gray-900, #181c32);
    }
  }

  .header-right {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    width: 100%;

    @media (max-width: 768px) {
      justify-content: space-between;
    }

    @media (min-width: 769px) {
      justify-content: flex-end;
    }
  }
}

.chat-card-messages-area {
  display: flex;
  flex-direction: column;
  align-items: center;
  //justify-content: flex-end;
  gap: 10px;
  padding: 20px;
  align-self: stretch;
  height: 100%;
  overflow: auto !important;
  scrollbar-width: none;
  -ms-overflow-style: none;
  transition: 1s;

  &.playground {
    opacity: 0.8;
    background-image: linear-gradient(var(--mantine-color-gray-2) 1px, transparent 1px), linear-gradient(to right, var(--mantine-color-gray-2) 1px, #fff 1px);
    background-size: 40px 40px;

    &::before {
      content: "PLAYGROUND MODE";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 1.5rem; // Adjust size as needed
      font-weight: bold;
      color: rgba(0, 0, 0, 0.2); // Semi-transparent for subtle effect
      pointer-events: none;
    }

    >* {
      position: relative;
      z-index: 1;
    }
  }

  &::-webkit-scrollbar {
    display: none;
  }

  .last-message-chip {
    border-radius: 18px !important;
    display: flex;
    height: 32px;
    font-weight: 600;
    padding: 6px 12px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    color: var(--Gray-500, #667085);
  }
}


.time-options-box {
  display: flex;
  padding: 10px 12px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  width: 180px;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.30), 0px 2px 6px 2px rgba(0, 0, 0, 0.15);
  border-radius: 12px;
  overflow: visible;
  gap: 5px;
}

.time-options-menu-item {
  display: flex;
  padding: 7px 10px;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 10px;
  cursor: pointer;
  color: #181C32;

  &.Mui-selected {
    background: #E4F2FD;
  }
}

button.chat-learn-more-button {
  color: var(--Gray-Gray-600, #7E8299);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  text-decoration-line: underline;

  :hover {
    background-color: initial;
    text-decoration-line: underline;
  }
}
