.ai-actionneeded-card {
  display: flex;
  padding: 12px;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
  border-radius: 16px;
  border: 1px solid #EBEBEB;
  background: #FAFAFA;
  margin-bottom: 12px;
}

.ai-suggestion-card {
  display: flex;
  padding: 12px;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
  border-radius: 16px;
  border: 1px solid #EBEBEB;
  background: var(--mantine-color-grape-1);
  margin-bottom: 12px;
  max-height: 400px;
  width: 100%;

  &.action-needed {
    background: var(--mantine-color-red-1);

  }

  .ai-image-box {
    display: flex;
    width: 40px;
    height: 40px;
    padding: 20px 18px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 100px;
    background: #C643FE;

    &.action-needed {
      background: var(--mantine-color-red-7);

      img {
        filter: invert(1) brightness(1000%) contrast(100%) grayscale(100%)
      }
    }
  }

  .ai-title {
    color: var(--Gray-Gray-900, #181c32);
    font-family: Inter;
    margin-top: 10px;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
  }

  .ai-scroll-box {
    overflow-y: hidden;
    overflow-x: auto;

    .ai-message-box {
      max-height: 200px;
      overflow-y: auto;
      padding: 10px 0px;
      margin-top: 0px;
      width: 100%;

      &::-webkit-scrollbar {
        display: none; // Hide scrollbar in Webkit browsers
      }

      .mobile-ai-approve-button .ai-description {
        width: 100%;
        color: var(--Gray-700, #344054);
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
      }
    }
  }

  .ai-response-button-box {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    margin-top: 0;
    flex-shrink: 0;
    width: 100%;

    .ai-response-button {
      cursor: pointer;
      color: var(--Gray-900, #101828);
      font-weight: 600;
      display: flex;
      flex: 1;
      gap: 8px;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      align-self: stretch;
      border-radius: 20px;
      border: 1px solid #79747E;
      background: var(--mantine-color-white);
      font-family: Inter, sans-serif;
      font-size: 14px;
      line-height: 24px;


      &:hover {
        background: var(--Schemes-On-Secondary, #fafafa);
      }

      &:active {
        background: var(--Schemes-On-Secondary, #dfdfdf);
      }

      &.circle {
        padding: 10px 8px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        flex: 1 0 0;
        align-self: stretch;
        max-width: 40px;
        max-height: 40px;
        border: 1px solid #79747E;
        border-radius: 100px;
      }

      &.suggestion {
        @media (max-width: 960px) {
          display: block;
          padding: 0px;
          border-radius: 100%;
          flex: none;
          max-width: 40px !important;
          width: 40px;
          height: 40px;
          border-radius: 100%;

          span {
            display: none;
          }
        }
      }

      &.action {
        background: var(--mantine-color-white);
        color: #000000;

        &:hover {
          background-color: #DDCACA;
        }

      }


    }
  }
}

.chatBoxStyles {

  .aiOptionButtons {
    display: flex;
    padding: var(--Spacing-space-6, 10px) 12px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex: 1 0 0;
    align-self: stretch;
    border-radius: 20px;
    border: 1px solid var(--Gray-500, #667085);
    background: var(--Schemes-On-Secondary, #FFF);
    color: #181C32;
    text-align: center;
    font-family: var(--Label-Large-Font, Inter);
    font-size: var(--Label-Large-Size, 14px);
    font-style: normal;
    font-weight: 600;
    line-height: var(--Label-Large-Line-Height, 20px);
    letter-spacing: var(--Label-Large-Tracking, 0.1px);
  }

  .aiOptionButtonsApprove {
    font-weight: 600;
    background-image: linear-gradient(0deg, #0065e0 0%, #0065e0 100%);
    color: var(--Schemes-On-Secondary, #fff);
  }

  .chatBox {
    max-width: 71%;
    min-width: 150px;
    padding: 12px;
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    border-radius: 16px;
  }

  .chatBoxProperty {
    background-color: #F2F7FD;
    align-self: flex-end;
  }

  .chatBoxUser {
    background-color: #F3F3F3;
    align-self: flex-start;
  }

  .chatBoxInternalNote {
    background-color: var(--Warning-50, #FFFAEB);
    align-self: flex-end;
  }

  .chatBoxDivider {}

  .chatBoxSelectDivider {
    border-radius: 16px;
    border: 1px solid #F1F1F2;
    display: flex;
    height: 32px;
    padding: 6px 12px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    color: var(--Gray-Gray-700, #5E6278);
    cursor: pointer;
    text-align: center;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
  }

  .applyButton {
    width: 80px !important;
    border-radius: 100px !important;
    background: var(--Main-blue, #0161E0) !important;
    padding: 10px;
    color: var(--Schemes-On-Secondary, #fff) !important;
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }

  .cancelButton {
    width: 80px !important;
    padding: 10px !important;
    border: 1px solid var(--Gray-500, #667085) !important;
    color: var(--Gray-800, #1D2939) !important;
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }

  .aiModal {
    display: flex;
    padding: 12px;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;
    border-radius: 16px;
    border: 1px solid #EBEBEB;
    background: #FAFAFA;
    margin-bottom: 12px;
  }

  .aiImage {
    display: flex;
    width: 40px;
    height: 40px;
    padding: 20px 18px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 100px;
    background: #C643FE;
  }

  .aiModalButtons,
  .aiModalActionButtons {
    color: var(--Gray-900, #101828);
    font-weight: 600;
    display: flex;
    gap: 8px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    border-radius: 20px;
    border: 1px solid #79747E;
    background: var(--Schemes-On-Secondary, #FFF);
  }

  .aiCirclebuttons {
    display: flex;
    padding: 10px 8px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex: 1 0 0;
    align-self: stretch;
    max-width: 40px;
    max-height: 40px;
    border: 1px solid #79747E;
    border-radius: 100px;
  }

  .popOver {

    & .MuiPopover-paper,
    & .MuiPaper-root {
      display: flex;
      padding: 0px 12px;
      flex-direction: column;
      align-items: flex-start;
      align-self: stretch;
      border-radius: 12px;
      background: linear-gradient(0deg, #FFF 0%, #FFF 100%), #F1F1F2;
      box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.3), 0px 2px 6px 2px rgba(0, 0, 0, 0.15);
    }
  }
}

.chatCardStyles {
  .select {
    min-width: 120px;
    padding: 6px 12px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    height: 32px;
    border-radius: 16px;
    color: var(--Gray-Gray-700, #5E6278);
    text-align: center;
    font-family: Inter !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 20px !important;
  }

  .menuItem {
    color: var(--Gray-Gray-700, #5E6278);
    text-align: center;
    font-family: Inter !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 20px !important;
  }

  .icon {
    color: #181C32;
  }

  .chip {
    display: flex;
    height: 32px;
    padding: 6px 12px;
    justify-content: center;
    align-items: center;
    gap: 6px;
  }

  .button {
    color: var(--Gray-Gray-600, #7E8299);
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    text-decoration-line: underline;

    &:hover {
      background-color: initial;
      text-decoration-line: underline;
    }
  }
}
