.appBar {
  display: flex;
  position: relative !important;
  width: 100%;
  padding: 0px 40px;
  margin: 0 auto;
  margin-bottom: "77px";
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ebebeb !important;
  background: #fff !important;
  box-shadow: none !important;
  z-index: 120 !important;
}

.appBar.onboarding {
  border-bottom: none !important;
}

.button-header-save-and-exit {
  padding: 12px 18px;
  display: flex;
  align-items: center;

  margin-top: 4px;
  outline: none;
  border-radius: 50px;
  cursor: pointer;
  border: 1px solid var(--Gray-Gray-800, #3f4254);
  background: #fff;
  height: 48px;
}

.toolbar {
  justify-content: space-between;
  align-items: center;
  width: 100% !important;
  padding: 0 40px !important;
  height: 100% !important;
}

.toolbar-mobile {
  display: flex;
  width: 100% !important;
  height: 100%;
  justify-content: space-between;
  align-items: center;
}

.logo {
  width: 106.817px;
  height: 32px;
  flex-shrink: 0;
}

.logo-mobile {
  width: 91.842px;
  height: 29.994px;
  flex-shrink: 0;
}

.menu {
  display: flex;
  padding: 16px 24px;
  align-items: center;
  gap: 20px;
  max-width: 488px;
  width: 100%;
  border-radius: 18px;
  border-radius: 24px;
}

.button {
  color: #7e8299 !important;
  display: flex;
  padding: 8px 4px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 6px;
}

:root {
  --blue: #0065e0;
}

.buttonText.active,
.icon.active {
  color: var(--blue);
}

.menuBox .button .icon {
  display: flex;
  width: 24px;
  height: 24px;
  justify-content: center;
  align-items: center;
}

.menuBox .button .buttonText {
  font-family: Inter !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 16px !important;
  /* 100% */
  text-transform: capitalize;
}

.userBox .iconButton {
  display: flex;
  height: 44px;
  padding: 8px;
  align-items: center;
  gap: 12px;
  border-radius: 6px !important;
}

.avatar {
  width: 40px;
  height: 40px;
  flex-shrink: 0;
  border-radius: 50%;
  object-fit: cover;
}

.arrowDown {
  display: flex;
  width: 24px;
  height: 24px;
  justify-content: center;
  align-items: center;
}

.menuPaper {
  // display: flex;
  // width: 360px;
  padding: 12px 20px !important;
  // flex-direction: column;
  // align-items: flex-end;
  // gap: 20px;
  // border-radius: var(--12, 12px) !important;
  // margin-right: 200px;
}

.menuItem {
  display: flex;
  width: 296px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
}

.menuArea {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
  align-self: stretch;
}
