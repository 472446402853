.container {
  position: relative; // TODO: Remove this line
  top: 77px; // TODO: Remove this line
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  overflow-x: hidden;

  @media (min-width: 576px) {
    min-width: 540px;
  }
  @media (min-width: 768px) {
    max-width: 720px;
  }
  @media (min-width: 992px) {
    max-width: 960px;
  }
  @media (min-width: 1200px) {
    max-width: 1200px;
  }
}
.container-datagrid-pages {
  position: relative; // TODO: Remove this line
  top: 77px; // TODO: Remove this line
  width: 100%;
  padding: 0 40px;
  margin-right: auto;
  margin-left: auto;
  overflow-x: hidden;

  @media (min-width: 576px) {
    max-width: 540px;
  }
  @media (min-width: 768px) {
    max-width: 720px;
  }
  @media (min-width: 992px) {
    max-width: 960px;
  }
  @media (min-width: 1200px) {
    max-width: 1430px;
  }
}

a {
  color: #06c;
}
.ql-snow .ql-editor a {
  color: #0065e0;
}
.ql-snow .ql-editor a:hover {
  color: #0056b3;
}
.ql-snow .ql-editor a:active {
  color: #004499;
}
.ql-snow .ql-editor a:focus {
  color: #0065e0;
}
.ql-snow .ql-editor a:visited {
  color: #0065e0;
}
.ql-snow .ql-editor a:link {
  color: #0065e0;
}

.container,
.container-datagrid-pages {
  position: relative; // TODO: Remove this line
  top: 77px; // TODO: Remove this line
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  overflow-x: hidden;
  overflow-y: hidden;

  @media (max-width: 576px) {
    max-width: 540px;
    margin: 0 20px;
    padding-bottom: 56px;
  }
}

.container-datagrid-pages-listening {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  overflow-x: hidden;
  overflow-y: hidden;
  padding: 0px 75px !important;
  
  @media (max-width: 576px) {
    max-width: 540px;
    margin: 0 20px;
    padding-bottom: 56px;
  }
}

input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}
.custom-checkbox {
  display: flex;
  width: 16px;
  height: 16px;
  padding: 3.2px 3.733px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5.333px;
  border-radius: 3.733px;
  border: 0.533px solid var(--Gray-Gray-500, #a1a5b7);
  position: relative;
}

.custom-checkbox input {
  display: none;
}

.checkmark {
  position: absolute;
  top: 1;
  left: 1;
  width: 100%;
  height: 100%;
  display: none;
}
.checkbox-label {
  font-size: 14px;
  color: black;
  font-weight: 400;
  font-family: Inter;
}
.custom-checkbox input:checked ~ .checkmark {
  display: block;
  width: 70%;
  height: 70%;
  background-color: transparent;
  border-radius: 2px;
  border: 1px solid transparent;
}
.custom-checkbox input:checked ~ .checkmark {
  display: flex;
  width: 16px;
  height: 16px;
  padding: 3.2px 3.733px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5.333px;
  box-shadow: 0 0 0 0.533px var(--Gray-Gray-500, #a1a5b7);
  border-radius: 3.733px;
  background-color: #0065e0;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: rgba(152, 130, 145, 0.6);
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.error-message {
  color: #d32f2f;
  font-size: 12px;
  font-weight: 400;
  font-family: Inter;
}
