.custom-select {
  position: relative;
  display: inline-block;
  min-width: 160px;

  &.no-w {
    min-width: 0px;
  }

  .cs-header {
    width: 100%;
    justify-content: center;
    transition: .3s;
    padding: 4px 8px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    &:hover {
      background-color: #f0f0f0;
    }

  }

  .cs-dropdown {
    min-width: fit-content;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    border: 1px solid #ddd;
    background-color: #fff;
    z-index: 1000;
    margin-top: 4px;
    border-radius: 0px 0px 8px 8px;
    overflow: hidden;
    transform: translateY(-10px);
    transform-origin: top;
    opacity: 0;
    transition: transform 0.3s ease, opacity 0.3s ease;
    max-height: 200px;
    will-change: transform, opacity;
    overflow: auto;
    pointer-events: none;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);

    &.open {
      transform: translateY(0);
      pointer-events: auto;
      opacity: 1;
    }
  }

  .cs-option {
    padding: 8px 16px;
    cursor: pointer;

    &:hover {
      background-color: #f0f0f0;
    }
  }
}
