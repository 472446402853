.profile-box-left-avatar-box {
  width: 160px;
  height: 160px;
  border-radius: 100px;

  @media screen and (max-width: 450px) {
    width: 120px;
    height: 120px;
  }
}
.profile-box-left-avatar-box img {
  width: 100%;
  height: 100%;
  border-radius: 100px;
  object-fit: cover;
}
.upload-photo-button {
  display: flex;
  padding: 12px 16px;
  align-items: center;
  gap: 10px;
  border-radius: 50px;
  border: 1px solid var(--Gray-Gray-800, #3f4254);
  color: var(--Gray-Gray-800, #3f4254);
  cursor: pointer;
  background-color: transparent;
}

.profile-settings-edit-button {
  display: flex;
  padding: 9px 12px;
  justify-content: space-between;
  align-items: center;
  border-radius: 50px;
  text-decoration: underline;
  color: var(--Gray-Gray-900, #181c32);
  border: none;
  background-color: transparent;
  cursor: pointer;
}
