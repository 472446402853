.messages-main-box {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border: none;
  width: 100%;
  min-height: 100%;
  height: 100%;
  min-width: 300px;
  max-width: 340px;
  background-color: #fff;
  border-right: none;
  background-color: transparent;
  border-right: 1px solid var(--mantine-color-gray-2);

  @media (max-width: 768px) {
    min-width: 100%;
    max-width: 100%;
    border: none;
  }

  .card-container {
    width: 100%;
    padding: 0;
    border-bottom: 1px solid #ebebeb;
    background: #fafafa;

    @media (max-width: 768px) {
      padding: 10px;
      padding-bottom: 10px;
    }

    .flex-box {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      align-self: stretch;
      width: 100%;
      padding: 20px 20px 10px 20px;

      @media (max-width: 768px) {
        padding: 0;
      }
    }

    .messages-card-filter-box {
      user-select: none;
      cursor: pointer;
      display: flex;
      align-items: center;
      gap: 10px;
      align-self: stretch;
      flex-direction: row;
      width: 100%;
      overflow-y: hidden;
      overflow-x: auto;
      padding-bottom: 20px;
      scrollbar-width: 1px;
      padding-right: 20px;

      &::-webkit-scrollbar {
        display: none;
      }

      .custom-chip {
        width: fit-content;
        display: flex;
        gap: 10px;
        padding: 4px 18px;
        justify-content: center;
        align-items: center;
        border-radius: var(--mantine-radius-xl);
        border: 1px solid var(--mantine-color-gray-5);
        background-color: #fff;

        span {
          text-wrap: nowrap;
          font-size: 14px;
          font-weight: 600;
          color: var(--mantine-color-dark-5)
        }


        &:nth-child(1) {
          margin-left: 20px;
        }

        &:hover {
          background-color: #f0f0f0;
        }

        &.active {
          border-color: var(--mantine-color-blue-5);
          background-color: var(--mantine-color-blue-5);
          color: #fff;

          &:hover {
            background-color: #0065E0
          }

          span {
            color: #fff
          }
        }

        .chip-count {
          display: flex;
          width: 20px;
          height: 20px;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 10px;
          border-radius: 1000px;
          color: #000;
          background-color: rgba(24, 28, 50, 0.90);

          span {
            text-align: center;
            font-size: 10px;
            font-weight: 600;
            color: #fff;
          }

          &.active {
            color: #fff;
            background-color: #fff;

            span {
              color: #000;
            }
          }
        }
      }
    }

    .messages-card-header {
      display: flex;
      align-items: center;
      gap: 102px;
      color: var(--Gray-Gray-900, #181c32);
    }


    .messages-card-settings-box {
      display: flex;
      flex-direction: row;
      height: 44px;
      padding: 8px 20px;
      background: transparent;
      align-items: center;
      gap: 12px;
      border-radius: 22px;
      background-color: #FFF;

      .icon-button {
        display: flex;
        width: 36px;
        height: 36px;
        padding: 4px;
        justify-content: center;
        align-items: center;
        border-radius: 22px;

        img {
          width: 20px;
          height: 20px;
          flex-shrink: 0
        }
      }
    }
  }
}


.message-card-search-text-box {
  .MuiInputBase-input::placeholder {
    color: var(--Gray-500, #667085);
    font-family: "Inter", sans-serif !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 400;
    line-height: 20px;
  }

  .MuiOutlinedInput-root {
    display: flex;
    height: 38px;
    margin: 3px;
    gap: 12px;
    align-items: center;
    padding: 8px 20px;
    border-radius: 20px;
    border: none;
    background: #fff;
    font-family: "Inter", sans-serif !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 400;
    line-height: 20px;
  }

  .MuiOutlinedInput-notchedOutline {
    border: 1px solid #E1E3EA;
  }
}

.messages-card-message-not-found-box {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100%;
  padding: auto;
  width: 100%;

  p {
    word-break: "break-word";
    text-align: "center";
    width: "100%";

  }
}

.messages-income-area {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;

  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }

  .message-income-card {
    width: 100%;
    cursor: pointer;
    border-bottom: 1px solid var(--mantine-color-gray-2);
    padding: 20px;


    &:hover {
      background: var(--mantine-color-gray-0);
    }

    &.active {
      background: var(--mantine-color-gray-1);
      border-color: var(--mantine-color-gray-2);
    }

    .message-income-ai-button {
      display: flex;
      flex-direction: row;
      justify-content: center;
      background: var(--mantine-color-red-1);
      align-items: center;
      align-self: stretch;
      font-weight: 600;
      border: 1px solid var(--mantine-color-gray-3);
      border-radius: var(--mantine-radius-xl);
      gap: 8px;
      cursor: pointer;
      padding: 6px 16px;
    }
  }

  .income-messages-message-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    color: var(--Gray-600, #475467);
    font-size: 14px;

    &.unread {
      font-weight: bold;
    }

    &.draft {
      color: var(--mantine-color-green-8);
    }
  }

  .message-card-unread-icon {
    width: 8px;
    height: 8px;
    background-color: #ff0000;
    border-radius: 50%;
    position: absolute;
    top: 12px;
    left: 12px;
  }


}

.messages-card-loading-box {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
  background: transparent;
  background-color: transparent;
}

.messages-card-scroll-top-button {
  position: fixed;
  bottom: 20px;
  left: 20px;
  z-index: 9999;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #0065e0 !important;
  color: #fff;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
  padding: 0;

  &:hover {
    background-color: #004bb5;
  }
}
