.chat-box {
  max-width: 71%;
  min-width: 150px;
  padding: 12px;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  border-radius: var(--mantine-radius-md);

  &.property {
    background-color: #F2F7FD;
    align-self: flex-end;
  }

  &.user {
    background-color: #F3F3F3;
    align-self: flex-start;
    // borderRadius: "10px",
    // padding: "10px",
    // maxWidth: "250px",
    // display: "flex",
  }

  &.internal-note {
    background-color: var(--Warning-50, #FFFAEB);
    align-self: flex-end;
  }

  &.loading {
    opacity: 0.5 !important;
  }


}

.message-inside-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  gap: 4px;
}

.ai-description-body {
  max-height: 200px;
  overflow-y: scroll;
  margin-bottom: 40px;
  padding-bottom: 10px;
  padding-top: 10px;
}

.message-inside-text-top {
  display: flex;
  align-items: center;
  gap: 8px;
}

.message-inside-text-name {
  color: var(--Gray-Gray-900, #181c32);
}

.message-inside-text-time {
  align-self: flex-end;
  color: var(--Gray-Gray-600, #7e8299);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.message-inside-text-message {
  align-self: stretch;
  color: var(--Gray-700, #344054);
  width: 100%;
  max-width: 100%;
  word-wrap: break-word;
  overflow-wrap: break-word;

  strong,
  b {
    font-weight: 700;
  }

  em {
    font-style: italic;
  }

  blockquote {
    border-left: 4px solid #ccc;
    padding-left: 1em;
    color: #666;
    margin: 1em 0;
  }

  ul {
    list-style-type: disc;
    padding-left: 1em;
  }

  ol {
    list-style-type: decimal;
    padding-left: 1em;
  }
}
