.onboarding-container {
  display: flex;
  // min-height: 528px;
  width: 100%;
  // padding: 56px 10px;
  // max-height: calc(100vh - 193px);
  box-sizing: border-box;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin: auto 0;
  padding: 56px 0px 154px 0px;
  // overflow: auto;

  @media screen and (max-width: 450px) {
    max-width: 450px;
    padding: 24px;
  }
}

.onboarding-center-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  // height: 579px;
  padding: 56px 0px;
}

.onboarding-text-center {
  color: var(--Gray-Gray-800, #3f4254);
  text-align: center;
  width: 705px;

  @media screen and (max-width: 450px) {
    max-width: 330px;
  }
}

.onboarding-center-image {
  display: inline-flex;
  padding: 23.699px 23.045px 29.232px 27.617px;
  justify-content: flex-end;
  align-items: center;
}

.onboarding-button {
  display: inline-flex;
  padding: 12px 18px;
  align-items: center;
  gap: 6px;
  border-radius: 50px;
  background: var(--Blue-light, #0065e0);
  color: #ffffff;
  border: none;
  cursor: pointer;
}

.button-icon {
  display: flex;
  width: 24px;
  height: 24px;
  padding: 5.186px 5.48px 5.19px 5.474px;
  justify-content: center;
  align-items: center;
  margin-left: 4px;
}

.button-icon img {
  width: 13.046px;
  height: 13.624px;
  flex-shrink: 0;
}

.onboarding-text-title {
  color: var(--Gray-Gray-800, #3f4254);
  text-align: left !important;
  margin-bottom: 32px;
}

.onboarding-categories-box {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 25px 24px;

  @media screen and (max-width: 450px) {
    grid-template-columns: repeat(1, 1fr);
    width: 100%;
  }
}

.category-image {
  width: 60px;
  height: 60px;
  flex-shrink: 0;
}

.category-image img {
  width: 60px;
  height: 60px;
  flex-shrink: 0;
}

.containerCategory {
  display: flex;
  align-items: flex-start;
  gap: 4px;
  flex-direction: column;
  justify-content: center;
  margin-left: 16px;
}

.infoCategoryText {
  color: #000;
}

.helperCategoryText {
  color: var(--Gray-Gray-700, #5e6278);
}

.onboarding-progress-bar {
  display: flex;
  flex-direction: row;
  bottom: 0;
  left: 0;
  width: 98%;
  height: 8px;
  gap: 12px;
}

.onboarding-listing-center-box {
  display: flex;
  align-items: center;
  height: 100%;
  overflow: auto;
}

.onboarding-listing-box {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 11px;

  @media screen and (max-width: 450px) {
    grid-template-columns: repeat(1, 1fr);
    width: 100%;
  }
}

.listing-images {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.listing-images img {
  width: 60px;
  height: 60px;
}

.infoListingText {
  color: var(--Gray-Gray-800, #3f4254);
}

.onboarding-address-center-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  // max-height: calc(100vh - 118px);
  // overflow: auto;
}

.onboarding-address-title {
  color: var(--white-dark-dark, #181c32);
  text-align: center;
}

.onboarding-address-input-area {
  display: flex;
  flex-direction: column;
  gap: 14px;
  width: 100%;
}

.onboarding-address-input-first-box {
  display: flex;
  flex-direction: row;
  gap: 18px;
}

.onboarding-address-input-country {
  width: 100%;
}

.onboarding-address-input-city {
  width: 100%;
}

.onboarding-address-input-title {
  align-self: stretch;
  color: var(--Gray-Gray-800, #3f4254);
  margin-bottom: 12px;
}

.location-title-box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;
  margin-bottom: 24px;
}

.location-title-box-left-area {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.location-title {
  color: var(--Gray-Gray-800, #3f4254);
}

.location-subtitle {
  color: var(--Gray-Gray-600, #7e8299);
}

.location-map-box {
  width: 560px;
  height: 280px;
  flex-shrink: 0;
  border-radius: 12px;
}

.location-map-box img {
  width: 560px;
  height: 280px;
  flex-shrink: 0;
  border-radius: 12px;
  object-fit: cover;
}

.map-marker {
  display: flex;
  width: 45px;
  height: 45px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 100px;
  border: 20px solid rgba(146, 185, 236, 0.51);
  background: var(--Blue-light, #0065e0);
}

.map-marker-inside-box {
  display: flex;
  width: 24px;
  height: 24px;
  padding: 1.981px 2px 2px 2px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}

.map-marker-inside-box img {
  width: 24px;
  height: 24px;
  flex-shrink: 0;
}

.map-tooltip {
  color: var(--Base-White, #fff);
  text-align: center;
}

.onboarding-is-address-correct-center-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
}

.onboarding-pages-titles-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  width: 100%;
}

.onboarding-pages-title {
  color: var(--white-dark-dark, #181c32);
}

.onboarding-pages-subtitle {
  width: 100%;
  color: var(--Gray-Gray-700, #5e6278);
}

.location-map-box-correct {
  width: 100%;
  height: 340px;
  flex-shrink: 0;
}

.map-marker-correct {
  display: flex;
  width: 50px;
  height: 50px;
  padding: 4.918px 8.245px 4.927px 8.323px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}

.property-describe-center-box {
  display: grid;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  width: 576px;
}

.property-descripe-title {
  color: var(--Gray-Gray-900, #181c32);
  text-align: left;
  margin-bottom: 44px;
}

.property-stack-descripe-text {
  color: var(--Gray-Gray-800, #3f4254);
  margin-top: 7px;
}

.property-stack-box {
  display: grid;
  grid-gap: 18px;
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
}

.property-descripe-mid-text {
  color: #000;
  text-align: center;
  margin-bottom: 18px;
  margin-top: 24px;
}

.property-descripe-bottom-text {
  color: var(--Gray-Gray-900, #181c32);
  text-align: left;
  margin-bottom: 18px;
  margin-top: 32px;
}

.property-descripe-bottom-second-text {
  color: var(--Gray-Gray-900, #181c32);
  text-align: left;
  margin-bottom: 12px;
  margin-top: 16px;
}

.property-descripe-bottom-box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 14px;
}

.place-basics-center-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.place-basics-items-box {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 39px;
  margin-top: 40px;
  width: 100%;
}

.place-basics-item-box {
  display: flex;
  width: 510px;
  padding-bottom: 24px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--Gray-Gray-400, #d8d8e5);
}

.place-basics-item-text {
  width: 173px;
  flex-shrink: 0;
  color: var(--Gray-Gray-900, #181c32);
}

.place-basics-item-value-box {
  display: flex;
  align-items: center;
  gap: 18px;
}

.place-basics-item-value-button {
  display: flex;
  width: 29px;
  height: 29px;
  padding: 9px;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border-radius: 60px;
  border: 1px solid var(--Gray-Gray-500, #a1a5b7);
  background: transparent;
  cursor: pointer;
}

.place-basics-item-value-button img {
  width: 11.667px;
  height: 11.717px;
  flex-shrink: 0;
}

.onboarding-step2-text {
  color: var(--Gray-Gray-800, #3f4254);
  font-family: Inter !important;
  margin-bottom: 12px;
}

.onboarding-step2-heading {
  color: var(--Gray-Gray-800, #3f4254);
  margin-bottom: 16px;
}

.onboarding-step2-subtext {
  color: var(--Gray-Gray-700, #5e6278);
}

.onboarding-step2-img-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 550px;
  height: 390px;
  display: inline-flex;
  padding: 3.5px;
  justify-content: flex-end;
  align-items: center;
}

.onboarding-step2-img-box img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.onboarding-step-two-1-top-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 44px;
}

.onboarding-step-two-1-top-box-title {
  color: var(--Gray-Gray-900, #181c32);
}

.onboarding-step-two-1-top-box-subtitle {
  color: var(--Gray-Gray-700, #5e6278);
}

.onboarding-step-two-1-top-box-items-box {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 19px;
  width: 100%;

  @media screen and (max-width: 450px) {
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
  }
}

.onboarding-step-two-1-top-box-select-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 18px;
  align-self: stretch;
}

.onboarding-step-two-1-top-box {
  color: var(--Gray-Gray-600, #7e8299);
}

.onboarding-step-two-1-mid-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
}

.onboarding-step-two-1-mid-box-text {
  color: var(--Gray-Gray-800, #3f4254);
}

.onboarding-step-two-1-bottom-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 18px;
  align-self: stretch;
}

.onboarding-step-two-1-bottom-box-text {
  color: var(--Gray-Gray-800, #3f4254);
}

.grouped-select-list-subheader {
  display: flex;
  padding: 11px 16px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  background: var(--white-dark-white, #fff);
  color: var(--Gray-Gray-700, #5e6278);
}

.grouped-select-menu-item {
  display: flex;
  padding: 12px 16px;
  align-items: center;
  align-self: stretch;
  background: var(--white-dark-white, #fff);
  color: var(--Gray-Gray-600, #7e8299);
}

.select-with-checkbox {
  display: flex;
  padding: 12px 16px;
  align-items: center;
  align-self: stretch;
}

.select-with-checkbox-checkbox {
  display: flex !important;
  width: 30px !important;
  height: 30px !important;
  padding: 6px 7px !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
  border-radius: 7px !important;
  margin-right: 10px !important;
}

.select-with-checkbox-text {
  color: var(--Gray-Gray-700, #5e6278);
  font-family: Inter !important;
  font-size: 13px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 14px !important;
}

.menu-item-text {
  color: var(--Gray-Gray-800, #3f4254) !important;
  font-family: Inter !important;
  font-weight: 500 !important;
}

.onboarding-photos-main-box {
  display: inline-flex;
  flex-direction: row;
  align-items: flex-start;
  height: 100%;
}

.onboarding-photos-center-box {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
}

.onboarding-main-photo-add {
  display: inline-flex;
  width: 480px;
  height: 334px;
  padding: 115px 81px 115px 82px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 12px;
  border: 1px dashed var(--Previous-500, #a1a5b7);
  margin-top: 48px;
  margin-bottom: 20px;
}

.no-border {
  border: none !important;
}

.onboarding-main-photo-add-area {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 14px;
}

.onboarding-main-photo-add-area-button {
  display: flex;
  padding: 10.667px;
  align-items: center;
  gap: 21.333px;
  border-radius: 100px;
  background: #f2f4f7;
  cursor: pointer;
  border: none;
}

.onboarding-main-photo-add-area-button-icon {
  width: 42.667px;
  height: 42.667px;
  flex-shrink: 0;
}

.onboarding-main-photo-add-area-button-text {
  color: var(--Primary-Primary, #3e97ff);
}

.onboarding-main-photo-add-area-texts {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.onboarding-main-photo-add-area-text {
  color: #0a2252;
  text-align: center;
}

.onboarding-main-photo-add-area-text-subtitle {
  color: #858c93;
  text-align: center;
}

.onboarding-photos-highlights-box {
  display: inline-flex;
  padding: 24px 36px 24px 24px;
  align-items: center;
  border-radius: 12px;
  border: 1px solid var(--Gray-Gray-300, #e1e3ea);
  margin-left: 81px;
  margin-top: 120px;
}

.onboarding-photos-highlights-box-inside {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
}

.onboarding-photos-highlights-box-title {
  color: var(--Gray-Gray-800, #3f4254);
  display: flex;
  width: 240px;
  align-items: center;
  gap: 35px;
}

.onboarding-photos-highlights-box-list {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
}

.onboarding-photos-highlights-box-list-item {
  display: flex;
  height: 20px;
  align-items: flex-start;
  gap: 10px;
  color: var(--Gray-Gray-700, #5e6278);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
}

.onboarding-main-photo-add-image {
  display: flex;
  width: 480px;
  height: 334px;
  padding: 1px;
  border-radius: 12px;
}

.onboarding-main-photo-add-image-remove-button {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 9px;
  gap: 10px;
  border-radius: 60px;
  background-color: var(--white-dark-white, #fff);
  box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.03);
  // z-index: 1;
  cursor: pointer;
  border: none;
}

.onboarding-main-photo-add-image-remove-icon {
  display: flex;
  width: 30px;
  height: 30px;
  padding: 7.2px 0px 6.913px 0px;
  justify-content: center;
  align-items: center;
}

.onboarding-small-photo-add-image {
  display: flex;
  width: 234px;
  height: 167px;
  padding: 1px;
  border-radius: 12px;
}

.onboarding-small-photo-add-area {
  display: inline-flex;
  height: 167px;
  width: 234px;
  padding: 0px 62px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 12px;
  border: 2px dashed var(--Gray-Gray-700, #5e6278);
}

.onboarding-small-photo-add-area-button {
  display: flex;
  padding: 9px 12px;
  align-items: center;
  gap: 5px;
  border-radius: 50px;
  border: 1px solid var(--Gray-Gray-600, #7e8299);
  background: transparent;
  cursor: pointer;
}

.onboarding-small-photo-add-area-button-icon {
  width: 11.667px;
  height: 11.667px;
  flex-shrink: 0;
}

.onboarding-step3-center-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  height: 100%;
}

.onboarding-step3-content-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
}

.onboarding-step3-content-item-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 11px;
  width: 100%;
}

.onboarding-step3-content-item-title {
  color: var(--Gray-Gray-800, #3f4254);
}

.onboarding-step3-content-item-subtitle {
  color: var(--Gray-Gray-600, #7e8299);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  /* 114.286% */
}

// .onboarding-step3-headline-textfield {
//   display: flex;
//   width: 480px;
//   height: 56px;
//   padding: 18px 16px;
//   align-items: flex-start;
//   gap: 10px;
//   border-radius: 6px;
//   align-self: stretch;
//   color: var(--Gray-Gray-600, #7e8299);
// }
.onboarding-step3-textfield-button {
  display: flex;
  width: 74px;
  height: 40px;
  padding: 12px 18px;
  align-items: center;
  gap: 2px;
  position: absolute;
  right: 6px;
  top: 8px;
  border-radius: 20px;
  background: #1a8e3a;
  cursor: pointer;
  border: none;
}

.onboarding-step3-textfield-button-icon {
  width: 16.667px;
  height: 16.667px;
  flex-shrink: 0;
}

.onboarding-step3-textfield-button-text {
  color: var(--white-dark-white, #fff);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  /* 114.286% */
}

.onboarding-step3-short-desc-textfield {
  display: flex;
  width: 480px;
  height: 120px;
  padding: 18px 16px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 6px;
}

.onboarding-step3-second-textfield-button {
  display: flex;
  width: 74px;
  height: 40px;
  padding: 12px 18px;
  align-items: center;
  gap: 2px;
  position: absolute;
  right: 6px;
  bottom: 8px;
  border-radius: 20px;
  background: #1a8e3a;
  cursor: pointer;
  border: none;
}

.onboarding-step3-price-box-price-area-price {
  height: 100%;
  width: 100%;
  border-radius: 6px;
  text-align: center;
  background-color: transparent;
}

.onboarding-step3-price-box-price-area-edit-button {
  display: flex;
  padding: 5.333px;
  align-items: flex-start;
  gap: 6.667px;
  border-radius: 33.333px;
  border: 0.667px solid var(--Gray-Gray-500, #a1a5b7);
  background: transparent;
  cursor: pointer;
}

.onboarding-step3-info-box {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 12px;
  align-self: stretch;
}

.onboarding-step3-info-box-text {
  color: var(--Gray-Gray-600, #7e8299);
}

.onboarding-step3-stays-box {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 18px;
}

.onboarding-step3-stays-box-stays-area {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
}

.onboarding-step3-stays-box-stays-area-min-stays {
  color: var(--Gray-Gray-800, #3f4254);
}

.onboarding-step3-stays-textfield {
  color: var(--Gray-Gray-900, #181c32);
}

.onboarding-step3-policy-area-info-box {
  display: flex;
  width: 100%;
  padding: 16px 14px;
  align-items: center;
  gap: 16px;
  border-radius: 7px;
  border: 1px solid var(--Primary-Primary, #3e97ff);
  background: var(--Primary-Primary-Light, #eef6ff);
}

.onboarding-step3-policy-area-info-text {
  color: var(--Primary-Primary, #3e97ff);
}

.onboarding-step3-policy-area-info-icon {
  width: 23.333px;
  height: 29.171px;
  flex-shrink: 0;
}


.onboardingImage {
  max-width: 503px;
  max-height: 399px;
}

.subtitleOfStep {
  color: var(--Previous-600, #7e8299);

  /* Text md/Bold */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  /* 150% */
}

.chatbotTitle {
  color: var(--Previous-800, var(--Previous-800, #3f4254));

  /* Display sm/Semibold */
  font-family: Inter;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 38px;
  /* 126.667% */
  margin-bottom: 8px;
}

.radioButtonDescription {
  color: var(--Previous-800, #3f4254);

  /* Text xl/Semibold */
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  /* 150% */
}

.listingTitle {
  color: var(--Previous-800, var(--Previous-800, #3f4254));

  /* Text sm/Semibold */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  /* 142.857% */
}

.listingSubtitle {
  color: var(--Previous-800, var(--Previous-800, #3f4254));

  /* Text md/Medium */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* 150% */
}

.properties-title {
  color: var(--Gray-700, #344054);

  /* Text sm/Semibold */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  /* 142.857% */
}

.TextXsRegular {
  color: var(--Previous-600, #7e8299);

  /* Text xs/Regular */
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  /* 150% */
}

//.property-tag {
//  display: flex;
//  padding: 4px 12px;
//  justify-content: center;
//  align-items: center;
//  gap: 7px;
//  color: var(--white-dark-white, #fff);
//  /* Text xs/Regular */
//  font-family: Inter;
//  font-size: 12px;
//  font-style: normal;
//  font-weight: 400;
//  line-height: 18px;
//  /* 150% */
//  border-radius: 50px;
//  background: var(--accent-color, #3e97ff);
//}

@media (max-width: 600px) {
  .property-tag {
    display: none;
  }

  .property-tag:nth-child(-n + 2) {
    display: block;
  }
}

.cancel-boarding {
  width: 24px;
  height: 24px;
  cursor: pointer;
  position: absolute;
  top: 16px;
  right: 20px;
}

.stepTwoIcons {
  width: 32px;
  height: 32px;
}

.titleOfStep {
  color: var(--Previous-900, #181c32);

  /* Display md/Semibold */
  font-family: Inter;
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: 44px;
  /* 122.222% */
  letter-spacing: -0.72px;
}

.secondaryDescription {
  color: var(--Previous-600, #7e8299);

  /* Text lg/Regular */
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
}

.labelSelect {
  color: var(--Previous-900, #181c32);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  /* 142.857% */
}

.add-listing {
  color: var(--accent-color, #3e97ff);
  text-align: center;
  cursor: pointer;

  /* Text sm/Medium */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  /* 142.857% */
}

.TextSmMedium {
  color: var(--Gray-600, #475467);
  /* Text sm/Medium */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  /* 142.857% */
}

.TextMdRegular {
  color: var(--Gray-400, #98a2b3);

  /* Text md/Regular */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 150% */
}

.TextSmRegular {
  color: var(--Gray-500, #667085);

  /* Text sm/Regular */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 142.857% */
}

.TextXsSemibold {
  color: var(--Gray-900, #101828);

  /* Display xs/Semibold */
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  /* 133.333% */
}

.showContentButtonStack {
  align-self: stretch;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 20px 0;
}

.getStartedImage {
  max-width: 450px;
  width: 100%;
}

.chip {
  display: flex;
  height: 26px;
  padding: 7px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 4px !important;
  font-size: 12px;
  font-weight: 600;
}

.tag {
  display: flex;
  height: 26px;
  padding: 4px 12px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 50px !important;
  font-size: 12px;
  font-weight: 500;
}

.TextMdSemiBold {
  color: var(--Previous-900, #181c32);

  /* Text md/Semibold */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  /* 150% */
}

.button-edit {
  display: flex;
  width: 28px;
  height: 28px;
  padding: 8px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: 1px solid var(--stroke-colors, #ebebeb);
  background: var(--Base-White, #fff);
  cursor: pointer;
  /* Shadow/xs */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}
