.reservation-image {
  width: 60px;
  height: 40px;
  border-radius: 4px;
}

.locationText {
  color: #3f4254 !important;
  margin-bottom: 7px !important;
  font-family: Inter;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 14px !important;
  white-space: pre-wrap;
}

.chipCustom {
  border-radius: 0;
  margin-right: 10px;
  padding: 1px;
}

.containerListingName {
  display: flex;
  align-items: flex-start;
  line-height: 1.4;
  flex-direction: column;
  justify-content: center;
  margin: 0 !important;
}

.containerId {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-top: 7%;
  line-height: 1.4;
  height: 100%;
  max-height: 95px;
  flex-direction: column;
}

.containerLocation {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin: 0;
  line-height: 1.4;
  height: 100%;
  max-height: 95px;
  flex-direction: column;
  word-wrap: break-word;
}

.alignCenter {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  height: 100%;
}

.alignCenter img {
  width: 50px;
  height: 50px;
}

.alignLeft {
  display: flex;
  justify-content: left;
  align-items: center;
  gap: 10px;
  height: 100%;
}

.alignLeft img {
  width: 50px;
  height: 50px;
}

.editIcon {
  cursor: pointer;
  width: 24px;
}

.listing-status-child {
  display: flex;
  padding: 24px 12px;
  align-items: flex-start;
  gap: 12px;
  width: 100%;
  border-bottom: 1px solid #ebebeb;
}

.listing-status-child-texts {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
}

.listing-status-child-texts-header {
  display: flex;
  align-items: center;
  gap: 4px;
}

.reserve-card-text {
  color: var(--Gray-Gray-900, #181c32);
  align-content: center;
  align-items: center;
}

.reserve-card-button {
  padding: 16px 24px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  border-radius: 50px;
  background: var(--Blue-light, #0065e0);
  border: none;
  cursor: pointer;
  color: var(--white-dark-white, #fff);
}

.reserve-card-info-text {
  line-height: 20px !important;
  color: var(--gray-500, #6b7280);
  text-align: center;
  align-self: stretch;
}

.reserve-card-discount-text {
  color: var(--Success-Success-Active, #47be7d);
  text-align: right;
}

.divider {
  width: 322px;
  height: 1px;
  background: #e5e7eb;
}

.reservation-card-report-listing {
  color: var(--gray-500, #6b7280);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 142.857% */
  text-decoration-line: underline;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.preview-listing-report-listing-img {
  width: 16px;
  height: 16px;
}

.mobile-menu-container {
  width: 100%;
  position: relative;
  margin-bottom: 30px;
  padding-right: 10px;
  background-color: #f9f9f9;
}

.menu-nav-button {
  background: rgba(255, 255, 255, 0.57);
  position: absolute;
  right: 0;
  padding: 0 0 0 10px;
  top: 39%;
}

.mobile-menu-tab {
  color: var(--Gray-Gray-600, #7e8299);
  padding: 16px 15px;
  /* Body/B-14-14-600 */
  font-family: Inter;
  cursor: pointer;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 14px;
  /* 100% */
  border-bottom: 1px solid #7e8299;
}

.mobile-menu-tab:nth-of-type(1) {
  padding-left: 0;
}

.mobile-menu-tab.active {
  color: var(--Gray-Gray-900, #181c32);

  /* Body/B-14-14-600 */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 14px;
  /* 100% */
  border-bottom: 1px solid #181c32;
}

.preview-listing-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
}

.preview-listing-img-small {
  max-width: 292px;
  width: 100%;
  max-height: 196px;
  object-fit: cover;
}

.preview-listing-header {
  display: flex;
  width: 239.28px;
  height: 36px;
  flex-direction: column;
  justify-content: center;
  color: #222;
}

.preview-list-back-button {
  border: none;
  background-color: transparent;
  cursor: pointer;
  width: 273px;
}

.preview-listing-reservation-card-modal-button {
  border: 1px solid var(--Gray-Gray-300, #e1e3ea);
  background-color: transparent;
  cursor: pointer;
  width: 100%;
  border-radius: 0px 0px 8px 8px;
  height: 52px;
  width: 100%;
}

.preview-listing-reservation-card-modal-date-button {
  border: 1px solid var(--Gray-Gray-300, #e1e3ea);
  background-color: transparent;
  cursor: pointer;
  width: 100%;
  border-radius: 8px 8px 0px 0px;
  height: 52px;
  width: 100%;
}

@media (max-width: 600px) {
  .rdp-month {
    align-items: center;
    justify-content: center;
  }
}
