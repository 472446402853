.listing-details-container {
  display: inline-flex;
  align-items: flex-start;
  gap: 88px;
  width: 100%;
}
.listing-details-route-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 18px;
  position: sticky;
  top: 10px;
}
.selected-listing-box {
  display: flex;
  width: 300px;
  padding: 16px 12px;
  justify-content: space-between;
  align-items: center;
  border-radius: 12px;
  border: 1px solid var(--Gray-Gray-300, #e1e3ea);
  background: transparent;
}
.selected-listing-box-details {
  display: flex;
  width: 242px;
  align-items: center;
  gap: 15px;
  flex-shrink: 0;
}
.selected-listing-box-details img {
  align-self: stretch;
  border-radius: 4px;
  width: 100px;
  height: 60px;
  object-fit: cover;
}
.selected-listing-box-details-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 7px;
  flex: 1 0 0;
}
.selected-listing-box-details-text-title {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  // -webkit-line-clamp: 1;
  align-self: stretch;
  color: var(--Gray-Gray-900, #181c32);
  text-overflow: ellipsis;
}
.selected-listing-box-details-text-subtitle {
  align-self: stretch;
  color: var(--Gray-Gray-700, #5e6278);
}
.selected-listing-box-icon {
  display: flex;
  width: 24px;
  height: 24px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  background-color: transparent;
  border: none;
  cursor: pointer;
}
.selected-listing-box-icon img {
  width: 24px;
  height: 24px;
  flex-shrink: 0;
}
.listing-details-links-box {
  display: flex;
  width: 296px;
  padding-right: 40px;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  flex: 1 0 0;
}
.listing-details-links-titles {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 12px 0px;
  align-items: left;
  gap: 49px;
  border: none;
  background-color: transparent;
  cursor: pointer;
}
.listing-details-links-title {
  color: var(--Gray-Gray-900, #181c32);
}
.listing-details-subitems-box {
  display: flex;
  width: 296px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  // border-radius: 6px;
  border-left: 3px solid #e1e3ea;
}
.listing-details-subitem-item {
  display: flex;
  padding: 10px 13px;
  align-items: center;
  gap: 13px;
  align-self: stretch;
  border-radius: 9px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  position: relative;
}
.listing-details-subitem-item-title {
  color: var(--Gray-Gray-900, #181c32);
  font-family: Inter !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 25px !important; /* 156.25% */
}
.listing-details-layout-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}
.listing-details-layout-box-header {
  display: flex;
  max-width: 812px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.listing-details-layout-box-header-texts-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
}
.listing-details-layout-box-header-title {
  color: var(--Gray-Gray-900, #181c32);
}
.listing-details-layout-box-header-subtitle {
  color: var(--Gray-Gray-600, #7e8299);
  width: 329px;
}
.listing-details-layout-box-header-buttons-box {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.listing-details-layout-box-header-button-one {
  display: flex;
  padding: 12px 18px;
  align-items: center;
  gap: 6px;
  border-radius: 50px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  text-decoration: underline;
}
.listing-details-status-icon {
  width: 12px;
  height: 12px;
  fill: var(--Success-Success-Active, #47be7d);
}
.listing-details-calendar-icon {
  width: 19px;
  height: 20.75px;
  flex-shrink: 0;
}
.listing-details-layout-box-header-button-one-text {
  color: var(--Gray-Gray-800, #3f4254);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 171.429% */
  text-decoration-line: underline;
}
.listing-details-layout-box-header-button-two {
  display: flex;
  padding: 9px 12px;
  align-items: center;
  gap: 5px;
  border-radius: 50px;
  border: 1px solid var(--Gray-Gray-800, #3f4254);
  background-color: transparent;
  cursor: pointer;
}
.listing-details-eye-icon {
  width: 16.667px;
  height: 14.267px;
  flex-shrink: 0;
}
.listing-details-layout-box-header-button-two-text {
  color: var(--Gray-Gray-800, #3f4254);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 171.429% */
}
.listing-details-layout-box-content-box {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 44px;
  width: 100%;
}
.listing-details-layout-box-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
}
.character-counter {
  color: var(--Gray-Gray-600, #7e8299);

  /* Body/B-12-12-700 */
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 12px;
  margin-top: 8px;
}
.listing-details-layout-box-content-title {
  color: var(--Gray-Gray-900, #181c32);
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 133.333% */
  letter-spacing: -0.18px;
}
.listing-details-room-title {
  color: var(--Gray-Gray-900, #181c32);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
}
.listing-details-room-count-title {
  color: var(--Gray-Gray-900, #181c32);
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  padding-top: 40px;
}
.remove-listing-room-button {
  border-radius: 50px;
  border: 1px solid #181c32;
  display: flex;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background-color: transparent;
  align-self: flex-start;
  color: #181c32;
  cursor: pointer;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
}
.listing-input-area-title {
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 133.333% */
}
.listings-details-edit-button {
  display: flex;
  min-width: 51px;
  padding: 9px 12px;
  justify-content: space-between;
  align-items: center;
  border-radius: 50px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  color: var(--Gray-Gray-900, #181c32);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px; /* 114.286% */
  text-decoration-line: underline;
}
.listing-details-layout-box-content-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
}

.listing-details-is-editing-box-close-button {
  display: flex;
  width: 24px;
  height: 24px;
  padding: 6.695px 6.705px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border: none;
  background-color: transparent;
  cursor: pointer;
  position: absolute;
  right: 24px;
  top: 24px;
}

.listing-details-is-editing-box-bottom-button-cancel {
  display: flex;
  padding: 12px 16px;
  align-items: center;
  gap: 10px;
  border-radius: 50px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  text-decoration: underline;
  color: var(--Gray-Gray-900, #181c32);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 14px; /* 107.692% */
}
.listing-details-is-editing-box-bottom-button-save {
  display: flex;
  width: 76px;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 50px;
  background: var(--Blue-light, #0065e0);
  border: none;
  cursor: pointer;
  color: var(--white-dark-white, #fff);
}
.price-of-night-box-center-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 32px;
  height: 100%;
}
.price-of-night-box-title-box {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}
.price-of-night-box-title-box-title {
  color: var(--Gray-Gray-900, #181c32);
}
.price-of-night-box-title-box-subtitle {
  color: var(--Gray-Gray-600, #7e8299);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}
.price-of-night-box {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  border-radius: 6px;
}
.price-of-night-box-price-area {
  display: flex;
  height: 48px;
  align-items: center;
  gap: 2px;
}
.price-of-night-box-price-area-price {
  color: var(--Gray-Gray-900, #181c32);
  font-family: Inter;
  font-size: 48px;
  font-style: normal;
  font-weight: 800;
  line-height: 48px; /* 100% */
  letter-spacing: -0.96px;
}
.price-input {
  width: 150px;
  border-radius: 6px;
  text-align: center;
  background-color: transparent;
  color: var(--Gray-Gray-900, #181c32);
  font-family: Inter;
  font-size: 48px;
  font-style: normal;
  font-weight: 800;
  line-height: 48px; /* 100% */
  letter-spacing: -0.96px;
}
.price-of-night-box-price-area-edit-button {
  display: flex;
  padding: 5.333px;
  align-items: flex-start;
  gap: 6.667px;
  border-radius: 33.333px;
  border: 0.667px solid var(--Gray-Gray-500, #a1a5b7);
  background: transparent;
  cursor: pointer;
}
.price-of-night-box-info-box {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  align-self: stretch;
}
.price-of-night-box-info-box-text {
  color: var(--Gray-Gray-600, #7e8299);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}
.price-of-night-box-info-box-text-price {
  color: var(--Gray-Gray-600, #7e8299);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
}
.check-in-box-checkbox-frame {
  display: flex;
  width: 152px;
  align-items: flex-start;
}
.check-in-box-checkbox-box {
  display: flex;
  align-items: center;
  gap: 10px;
}
.check-in-box-checkbox-label {
  color: var(--Gray-Gray-800, #3f4254);
  font-weight: 400;
}
.input-area-title {
  color: var(--Gray-Gray-900, #181c32);
}
.preview-listing-avatar-img {
  width: 50px;
  height: 50px;
  flex-shrink: 0;
  border-radius: 100px;
  object-fit: cover;
}
.show-more-button {
  color: var(--Gray-Gray-900, #181c32);
  background: transparent;
  border: none;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  text-decoration: underline;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0;
}
.preview-listing-card {
  border: 1px solid var(--stroke-colors, #ebebeb);
  border-radius: 6px;
  padding: 16px 24px;
  width: 300px;
  gap: 10px;
}
.preview-listing-card-img {
  width: 24px;
  height: 24px;
  flex-shrink: 0;
}
.preview-listing-card-text {
  color: var(--Gray-Gray-900, #181c32);
}
.preview-listing-offer-text {
  align-items: center;
  align-content: center;
}

.cover-photo-title {
  color: var(--Gray-Gray-900, #181c32);
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 133.333% */
  letter-spacing: -0.18px;
}
.cover-photo-subtitle {
  color: var(--Gray-Gray-600, #7e8299);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}
.change-photo-button {
  display: flex;
  padding: 12px 16px;
  max-width: 140px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 50px;
  border: 1px solid var(--Gray-Gray-800, #3f4254);
  color: var(--Gray-Gray-800, #3f4254);
  background-color: transparent;
  cursor: pointer;
}
.upload-photos-photo-description {
  color: var(--Gray-Gray-900, #181c32);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  align-content: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}
.upload-photos-edit-button {
  display: flex;
  width: 51px;
  padding: 9px 12px;
  justify-content: space-between;
  align-items: center;
  border-radius: 50px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  color: var(--Gray-Gray-900, #181c32);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px; /* 114.286% */
  text-decoration-line: underline;
}
.all-photos-menu-button {
  position: absolute;
  right: 8px;
  top: 8px;
  display: flex;
  width: 38px;
  height: 38px;
  padding: 9px;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 60px;
  background: var(--white-dark-white, #fff);
  justify-content: center;
  cursor: pointer;
  border: none;
}
.all-photos-uploaded-photo {
  width: 100%;
  height: 160px;
  border-radius: 12px;
  object-fit: cover;
  user-select: none;
  pointer-events: none;
}
.all-photos-uploaded-photo-menu {
  width: 157px;
  padding: 20px 0px;
  align-items: flex-start;
  position: absolute;
  right: 9px;
  top: 54px;
  border-radius: 6px;
  border: 1px solid #f1f1f2;
  background: var(--white-dark-white, #fff);
  box-shadow: 0px 8px 14px 0px rgba(15, 42, 81, 0.08);
  z-index: 99;
}
.menu-item-button {
  padding: 12px 16px;
  width: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
  background-color: transparent;
  border: none;
  color: var(--Gray-Gray-600, #7e8299);

  &:hover {
    color: var(--Primary-Primary, #3e97ff);
    background: var(--Primary-Primary-Light, #eef6ff);
  }
}
