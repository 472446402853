.title-box-h3-box {
  display: flex;
  padding: 32px 0px;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  border-bottom: 1px solid var(--stroke-colors, #ebebeb);
}
.title-box-h3-box-left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
  flex: 1 0 0;
}
.title-box-h3-box-title {
  align-self: stretch;
  color: var(--Gray-Gray-900, #181c32);
}
.title-box-h3-box-subtitle {
  color: var(--Gray-Gray-600, #7e8299);
  align-self: stretch;
}
