.chat-text-field-box {
  margin-top: 0px;
  align-items: flex-start;
  align-self: stretch;
  width: 100%;
  padding: 0 20px 12px 20px;
  border-radius: var(--mantine-radius-xl);
  flex-wrap: nowrap;

  @media (max-width: 768px) {
    margin-bottom: 4px;
    padding: 0;
  }

  .chat-text-field {
    border-radius: var(--mantine-radius-md) var(--mantine-radius-md) 0 0;
    background-color: #fff;
    border: 1px solid #ebebeb;
    padding-left: 10px;

    &:hover {
      border-color: transparent;
    }
  }

  .chat-back-button {
    cursor: pointer;
    position: absolute;
    width: 32px;
    justify-content: center;
    align-items: center;
    height: 32px;
    border-radius: 50%;
    left: 10px;
    background: #0161e0;
    bottom: 35px;
    display: hidden;

    &:hover {
      opacity: 0.8;
    }

    @media (max-width: 768px) {
      display: block;
    }
  }

  .textfield-extras {
    display: flex !important;
    flex-direction: row !important;
    justify-content: space-between;
    margin-top: 0;
    padding: 12px;
    padding-left: 20px;
    align-items: center;
    align-self: stretch;
    border-radius: 0 0 var(--mantine-radius-md) var(--mantine-radius-md);
    border: 1px solid #ebebeb;
    border-top: none;
    height: auto;


    @media (max-width: 768px) {
      position: absolute;
      bottom: 35px;
      right: 0;
      margin-top: 1px;
      padding: 0 20px;
      border: none;
      height: 35px;
    }

    .chat-text-field-send-button {
      justify-content: center;
      align-items: center;
      gap: 8px;
      transition: 1s;
      background-color: #0065e0;
      color: #fff;
      min-width: 80px;
      font-size: 16px !important;
      text-transform: none;

      //  &:hover {
      //    background-color: #0051c7;
      //  }

      @media (max-width: 768px) {
        position: absolute;
        right: 10px;
        padding: 0;
        min-width: 32px;
        border-radius: 100px;
        width: 32px;
        height: 32px;
      }

    }
  }
}
