.autopilot-main {
  padding: 20px;
  gap: 30px;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: calc(100% - 20px);

  @media (min-width: 1300px) {
    max-width: 60%;
  }

  .autopilot-header {
    gap: 10px;
    width: 100%;
    align-self: flex-start;
    display: flex;
    flex-direction: column;
  }
}
