.mui-filter-drawer {
  top: 75px;
  width: calc(27% - 10px);
  left: 0;
  max-width: 340px;

  @media (max-width: 768px) {
    width: 100%;
    max-width: 100% !important;
  }
}

.filter-drawer {
  width: 100%;
  //max-width: 335px;
  height: auto;
  overflow: auto;
  padding: 20px;
  max-height: 100vh;
  background-color: #fff;
  display: flex;
  flex-direction: column;

  .main-flex-box {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .filter-header {
      display: flex;
      direction: row;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;

      .filter-title {
        color: var(--Gray-Gray-900, #181c32);
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
      }

      .filter-reset {
        color: var(--Blue-light, #0065e0);
        text-align: center;
        font-family: Inter;
        cursor: pointer;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        /* 142.857% */
      }
    }

    .form-flex-box {
      width: 100%;
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 16px;

      .form-control-label {
        margin: 0px;

        &.MuiFormControlLabel-label {
          color: #667085;
          font-family: Inter;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
        }
      }

      .MuiCheckbox-root {
        padding: 0px;
        margin-right: 8px;

        & .MuiSvgIcon-root {
          color: #98A2B3;
        }

        &.Mui-checked .MuiSvgIcon-root {
          color: #0065E0;
        }

        &:hover {
          background-color: transparent;
        }

        width: 20px;
      }

      .filter-input-label {
        color: var(--Gray-Gray-900, #181c32);
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
      }

      div,
      p {
        width: 100%;
      }
    }
  }



}

.buttons-box {
  width: 100%;
  display: flex;
  padding: 16px;
  border-top: 1px solid #EBEBEB;
  justify-content: space-between;
  gap: 20px;


  .c-button {
    border-radius: 100px !important;
    padding: 10px;
    flex: 1;
    text-align: center;
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;

    &.apply {
      background: var(--Main-blue, #0161E0) !important;
      color: var(--Schemes-On-Secondary, #fff) !important;
    }

    &.cancel {
      border: 1px solid var(--Gray-500, #667085) !important;
      color: var(--Gray-800, #1D2939) !important;
    }
  }
}
