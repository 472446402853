.document-upload-modal-content {
  border-radius: var(--mantine-radius-md) !important;

  .dropzone-outline {
    border: 2px dashed var(--mantine-color-gray-4);
    border-radius: var(--mantine-radius-lg);

    .upload-image-wrapper {
      padding: 10px;
      border-radius: 100%;
      background: #fafafa;
    }
  }

  .file-box-close-icon {
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;

    &:hover {
      background: #efefef;
    }
  }

  .files-box {
    overflow: auto;
    max-height: 500px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 10px 0;
    margin: 10px 0;
  }
}
