.onboarding-main {
  display: flex;
  flex-direction: column;
  //TOP BAR AND BELOW BAR HEIGHT + 10px for padding
  height: calc(100vh - (80px + 90px));
  padding: 0px 20px;
  overflow-y: auto;

  .onboarding-wrapper {
    margin: 0px 100px;
    width: auto;

    &.no-m {
      margin: 0px;
    }
  }


}

.onboarding-bottom-box {
  width: 100%;
  position: fixed;
  bottom: 0;
  background: white;
  height: 96px;

  .onboarding-progress-bar {
    display: flex;
    flex-direction: row;
    bottom: 0;
    left: 0;
    width: 98%;
    height: 8px;
    gap: 12px;
  }




}

.onboarding-bottom-buttons {
  width: 100%;
  display: flex;
  padding: 20px 120px;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 768px) {
    padding: 20px;
  }
}
