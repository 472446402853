  .chat-bot-main {
    display: flex;
    padding: 16px;
    flex-direction: column;
    justify-content: center;
    position: relative;
    align-items: flex-start;
    border-radius: 12px;
    gap: 30px;
    width: 100%;
    max-width: 100%;
    border: 1px solid var(--mantine-color-gray-2);
    background: var(--mantine-color-gray-0);
    border-radius: 12px;

    .chat-bot-property-container {
      display: flex;
      position: relative;
      cursor: pointer;
      padding: 6px 16px;
      gap: 10px;
      align-items: center;
      justify-content: space-between;
      border-radius: var(--radius-sm, 6px);
      border: 1px solid var(--stroke-colors, #EBEBEB);
      width: 100%;
      background: var(--Base-White, #FFF);

      &.active {
        background: var(--mantine-color-blue-0);
      }

      .property-box {
        overflow: hidden;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 16px;

        &.tags {
          font-size: 14px;
          gap: 12px;
        }

        .property-tag-box {
          flex-wrap: wrap;
          gap: 10px;
          display: flex;
          flex: 1;
          overflow: hidden;

          .property-tag {
            background: var(--mantine-color-blue-5);
            border-radius: var(--mantine-radius-lg);
            display: flex;
            font-size: 14px;
            overflow: hidden;
            color: white;
            justify-content: center;
            padding: 0px 12px;
            flex-shrink: 1;

            p {
              padding: 4px 12px;
            }
          }
        }


      }

      .chat-bot-property-buttons {
        min-width: fit-content;

        .property-button {
          flex: 1;
          padding: 6px;

          &:hover {
            text-decoration: underline !important;
          }
        }

      }


    }
  }
