.list-property-modal {
  border-radius: var(--mantine-radius-md) !important;

  .property-list-main-box {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100%;
    min-height: 60vh;
    max-height: 550px;
    width: 100%;
    overflow: hidden;

    .property-list-body {
      display: flex;
      gap: 20px;
      height: 100%;
      min-height: calc(60vh - 60px);
      max-height: calc(100vh - 200px);
      overflow: hidden;

      @media (min-width: 768) {
        //  flex-direction: column;
      }

      .property-list-inner {
        gap: 10px;
        display: flex;
        flex-direction: column;
        border: 1px var(--mantine-color-gray-3) solid;
        border-radius: var(--mantine-radius-md);
        flex: 1;
        overflow: hidden;

        .property-list-header {
          display: flex;
          flex-direction: column;
          gap: 10px;
          padding: 12px;
        }

        .property-list-scroll-area {
          max-height: 100%;
          height: 100%;
          width: 100%;
          overflow: auto;

          .list-item {
            gap: 20px;
            align-items: center;
            display: flex;
            position: relative;
            cursor: pointer;
            padding: 12px 26px;
            width: 100%;
            min-width: 370px;

            &.active {
              color: gray;
              background-color: #f0f0f0;
              filter: grayscale(100%);
            }

            &:hover {
              background: #f1f1f1;
            }

            border-bottom: 1px solid #e1e3ea;

            .close-icon {
              cursor: pointer;
              position: absolute;
              top: 10px;
              right: 10px;

              &:hover {
                background: #efefef;
              }
            }


            .details {
              display: flex;
              gap: 20px;
              align-items: center;

              .text-flex {
                display: flex;
                flex-direction: column;
              }
            }
          }
        }
      }
    }
  }
}
